import React, { useEffect, useState, Suspense, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Typography, Pagination } from 'antd';
import './styles.less';
import {
  Filter,
  DoubleArrow,
  BulletListText,
  AlertTriangle,
  Plus,
} from '@combateafraude/icons/general';
import usePagination from 'customHooks/usePagination';
import { useFetch } from 'services/hooks';
import NotAvailable from 'components/NotAvailable';
import Loading from 'components/Loading';
import sift from 'sift';
import RelationshipList from '../RelationshipList';
import FormComponent from './MoreRelationshipComponents/Form';

const { Text } = Typography;

const RelationshipTreeLazy = React.lazy(() =>
  import('./MoreRelationshipComponents/RelationshipTree')
);

const MoreRelationshipsContent = ({
  peopleData,
  handleExpandMoreRelationshps,
  hasAcceptedExtendeRelationships,
  getHasMoreRelationships,
}) => {
  const { entityType, businessIdentityId } = peopleData.businessIdentity;
  const { t: translate, ready } = useTranslation('kyb-more-relationships');
  const [formIsVisible, setFormIsVisible] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [filterQuery, setFilterQuery] = useState(null);
  const { post: postMethod, loading: fetchLoading, error } = useFetch();
  const [filteredData, setFilteredData] = useState(null);
  const { transactionId } = useParams();

  const loading = fetchLoading || !ready;

  const [isTreeView, setIsTreeView] = useState(false);

  const handleToggle = () => {
    setIsTreeView(!isTreeView);
  };

  const topRef = useRef(null);

  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 10,
  });

  const handleChangePage = (page) => {
    setPagination((old) => ({ ...old, currentPage: page }));
    topRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const { paginatedItems } = usePagination({
    pagination,
    items: filteredData?.data || [],
  });

  const fetchAPISayari = async (offset) => {
    return postMethod({
      url: '/company-explore',
      payload: {
        transactionId,
        parameters: {
          businessIdentityId,
          filters: {
            limit: 50,
            offset: offset || 0,
          },
        },
      },
    });
  };

  useEffect(() => {
    async function fetchData() {
      if (!businessIdentityId || !transactionId) return [];
      const response = await fetchAPISayari();

      setApiResponse(response.data);
      setFilteredData(response.data);
    }

    fetchData();
  }, [businessIdentityId, transactionId]);

  const handleFormClear = () => {
    setFilteredData({ ...apiResponse });
  };

  const handleFormFilter = (formValues) => {
    const excludePossibly = formValues.exclude?.includes('excludePossibly');
    const excludeEntities = formValues.exclude?.includes('excludeEntities');
    if (!apiResponse || !apiResponse.data) return;

    const query = {
      $and: [
        formValues.name
          ? { 'target.name': { $regex: new RegExp(formValues.name, 'i') } }
          : {},

        formValues.relationship?.length > 0
          ? { 'path.field': { $in: formValues.relationship } }
          : {},

        formValues.entity?.length > 0
          ? { 'path.entity.entityType': { $in: formValues.entity } }
          : {},

        formValues.country?.length > 0
          ? { 'path.entity.countries': { $in: formValues.country } }
          : {},

        formValues.exclude?.length > 0
          ? { 'path.entity.countries': { $in: formValues.country } }
          : {},

        excludePossibly ? { psa_count: { $gt: 0 } } : {},

        excludeEntities ? { closed: false } : {},

        formValues.riskFactor?.length > 0
          ? {
              'path.entity.risk': {
                $elemMatch: {
                  name: formValues.riskFactor,
                },
              },
            }
          : {},
      ],
    };
    const filtered = apiResponse.data.filter(sift(query));
    setFilterQuery(query);
    setFilteredData({ ...apiResponse, data: filtered });
    setPagination((old) => ({ ...old, currentPage: 1 }));
  };

  const showWarningMessage =
    pagination.currentPage % 5 === 0 &&
    apiResponse.next === true &&
    filteredData.data.length / pagination.currentPage === 10 &&
    !loading;

  const hidePagination =
    loading || !apiResponse?.data?.length || (filterQuery && !filteredData?.data.length);

  useEffect(() => {
    async function fetchData() {
      if (showWarningMessage && hasAcceptedExtendeRelationships) {
        const response = await fetchAPISayari(apiResponse?.data?.length);

        if (!response) return;
        setApiResponse({
          next: response.data.next,
          data: [...apiResponse.data, ...response.data.data],
        });

        setFilteredData({
          next: response.data.next,
          data: [...filteredData.data, ...response.data.data],
        });

        setPagination((old) => ({ ...old, currentPage: pagination.currentPage + 1 }));

        if (response.data.next) {
          getHasMoreRelationships();
        }
      }
    }

    fetchData();
  }, [hasAcceptedExtendeRelationships]);

  return (
    <div className="kyb-more-relationship-wrapper">
      <div className="kyb-more-relationship-container">
        <div className="kyb-more-relationship-header-text">
          <Text>{translate(`moreRelationships.headerText`)}</Text>
        </div>
        <div className="kyb-more-relationship-filter-button-container">
          <Button
            type="primary"
            shape="circle"
            icon={<Filter />}
            onClick={() => setFormIsVisible(!formIsVisible)}
            className="kyb-more-relationship-filter-button"
          />
          <Button
            type="primary"
            shape="default"
            onClick={handleToggle}
            className={`kyb-more-relationship-toggle-button ${
              isTreeView ? 'toggled' : ''
            }`}
          >
            <span className="toggle-icons">
              <BulletListText className="icon-left" />
              <DoubleArrow className="icon-right" />
            </span>
          </Button>
        </div>
      </div>
      <FormComponent
        visible={formIsVisible}
        onSubmit={handleFormFilter}
        onClear={handleFormClear}
      />
      {loading && <Loading />}
      {!error ? null : <NotAvailable card />}
      {!error && !loading && !isTreeView ? (
        <RelationshipList payload={paginatedItems} entityType={entityType} />
      ) : null}
      {!error && !loading && isTreeView ? (
        <Suspense fallback={<Loading />}>
          <div key={pagination.currentPage}>
            <RelationshipTreeLazy peopleData={peopleData} payload={paginatedItems} />
          </div>
        </Suspense>
      ) : null}
      <div className="kyb-relationship-container">
        {showWarningMessage && (
          <div className="kyb-relationship-warning-container">
            <div className="kyb-relationship-warning-message">
              <AlertTriangle width={24} height={24} />
              <span>{translate(`moreRelationships.pagination_warning`)}</span>
            </div>
            <Button
              className="kyb-relationship-expand-button"
              type="primary"
              onClick={() => handleExpandMoreRelationshps()}
            >
              {translate(`moreRelationships.expand_relationships`)}{' '}
              <Plus width={20} height={20} />
            </Button>
          </div>
        )}
        {hidePagination ? null : (
          <div className="kyb-relationship-pagination" ref={topRef}>
            <Pagination
              className="kyb-relationship-pagination-control"
              current={pagination.currentPage}
              onChange={handleChangePage}
              pageSize={pagination.pageSize}
              total={filteredData?.data.length || 0}
              defaultCurrent={1}
              showSizeChanger={false}
              showSorterTooltip={false}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MoreRelationshipsContent;
