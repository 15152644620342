export const findSourceSection = (data, source) => {
  const section = data?.sections || data?.sources || {};
  return section[source];
};

export const hasAddressInformation = (execution, dataSources) => {
  return (
    dataSources?.includes('pf_addresses') ||
    execution?.addresses ||
    execution?.sections?.pfAddresses
  );
};

export const hasContactsInformation = (execution, dataSources) => {
  const sources = [
    'pfEmails',
    'pfPhones',
    'pjEmails',
    'pjPhones',
    'emailAgePrivate',
    'zipCodeDetails',
  ];

  const foundSource = sources.find((s) => findSourceSection(execution, s));

  return (
    foundSource ||
    dataSources?.includes('pf_phones') ||
    dataSources?.includes('pf_emails') ||
    dataSources?.includes('zip_code_details') ||
    execution?.contact?.phones ||
    execution?.contact?.emails ||
    execution?.contact?.zipCodeDetails
  );
};

export const hasPJFinancialInformation = (execution, dataSources) => {
  const sources = [
    'pjFinantialData',
    'pjSocialAssistance',
    'pjCollections',
    'pjSeguroDesemprego',
    'pjPgfn',
    'pjCoaf',
    'pjVehicles',
    'pjSpc',
    'pjProtesto',
    'pjSpcIncome',
    'pjScoreCheck',
    'pjBigidSerasa',
    'pjSpcRestrictionsScore',
    'pjSpcCompanyReportParticipationScore',
    'pjAssociatedCvm',
    'pjCompanyOwnersPercentage',
    'pjPgfnRegularCertificate',
    'pjPgfnDebtors',
    'pjPenaltiesOnCvm',
    'pjCreditOperationsScr',
  ];

  const foundSource = sources.find((s) => findSourceSection(execution, s));

  return (
    foundSource ||
    execution?.sections?.pjFinantialData ||
    execution?.sections?.pjSocialAssistance ||
    execution?.sections?.pjCollections ||
    execution?.sections?.pjSeguroDesemprego ||
    execution?.sections?.pjPgfn ||
    execution?.sections?.pjCoaf ||
    execution?.sections?.pjVehicles ||
    execution?.sections?.pjSpc ||
    execution?.sections?.pjProtesto ||
    execution?.sections?.pjSpcIncome ||
    execution?.sections?.pjScoreCheck ||
    execution?.sections?.pjSpcRestrictionsScore ||
    execution?.sections?.pjSpcCompanyReportParticipationScore ||
    execution?.sections?.pjAssociatedCvm ||
    execution?.sections?.pjCompanyOwnersPercentage ||
    execution?.sections?.pjPgfnRegularCertificate ||
    execution?.sections?.pjPgfnDebtors ||
    execution?.sections?.pjPenaltiesOnCvm ||
    execution?.sections?.pjCreditOperationsScr ||
    dataSources?.includes('pj_credit_operations_scr') ||
    dataSources?.includes('pj_score_check') ||
    dataSources?.includes('pj_protesto')
  );
};

export const hasPFFinancialInformation = (execution, dataSources) => {
  const sources = [
    'pfFinantialData',
    'pfSocialAssistance',
    'pfCollections',
    'pfSeguroDesemprego',
    'pfPgfn',
    'pfCoaf',
    'pfVehicles',
    'pfSpc',
    'pfProtesto',
    'pfSpcIncome',
    'pfScoreCheck',
    'pfBigidSerasa',
    'pfSpcRestrictionsScore',
    'pfIbopeIncome',
    'pfEstimatedIncome',
    'pfBacenQgi',
    'pfAssociatedCvm',
    'pfPgfnRegularCertificate',
    'pfPgfnDebtors',
    'pfPenaltiesOnCvm',
    'pfCreditOperationsScr',
    'pfMbsArcaMs2',
  ];

  const foundSource = sources.find((s) => findSourceSection(execution, s));

  return (
    foundSource ||
    execution?.sections?.pfFinantialData ||
    execution?.sections?.pfSocialAssistance ||
    execution?.sections?.pfCollections ||
    execution?.sections?.pfSeguroDesemprego ||
    execution?.sections?.pfPgfn ||
    execution?.sections?.pfCoaf ||
    execution?.sections?.pfVehicles ||
    execution?.sections?.pfSpc ||
    execution?.sections?.pfProtesto ||
    execution?.sections?.pfSpcIncome ||
    execution?.sections?.pfScoreCheck ||
    execution?.sections?.pfSpcRestrictionsScore ||
    execution?.sections?.pfIbopeIncome ||
    execution?.sections?.pfEstimatedIncome ||
    execution?.sections?.pfBacenQgi ||
    execution?.sections?.pfAssociatedCvm ||
    execution?.sections?.pfPgfnRegularCertificate ||
    execution?.sections?.pfPgfnDebtors ||
    execution?.sections?.pfPenaltiesOnCvm ||
    execution?.sections?.pfMbsArcaMs2 ||
    execution?.sections?.pfCreditOperationsScr ||
    dataSources?.includes('pf_credit_operations_scr') ||
    dataSources?.includes('pf_score_check') ||
    dataSources?.includes('pf_finantial_data') ||
    dataSources?.includes('pfFinantialData') ||
    dataSources?.includes('pf_spc') ||
    dataSources?.includes('pf_spc_income') ||
    dataSources?.includes('pf_protesto') ||
    dataSources?.includes('pf_pgfn') ||
    dataSources?.includes('pf_joint_debts_certificate') ||
    dataSources?.includes('pf_social_assistance') ||
    dataSources?.includes('pfSocialAssistance') ||
    dataSources?.includes('pf_seguro_desemprego') ||
    dataSources?.includes('pfSeguroDesemprego ') ||
    dataSources?.includes('pf_salesbox') ||
    dataSources?.includes('pf_estimated_income')
  );
};

export const hasFinancialInformation = (execution, dataSources) => {
  return (
    execution?.finance?.totalAssets ||
    execution?.finance?.incomeEstimates ||
    execution?.finance?.taxReturns ||
    execution?.finance?.spc ||
    execution?.finance?.protest ||
    execution?.finance?.socialAssistance ||
    execution?.finance?.scoreCheck ||
    execution?.certificates?.pgfn ||
    execution?.certificates?.jointDebts ||
    execution?.work?.unemploymentInsurance ||
    hasPJFinancialInformation(execution, dataSources) ||
    hasPFFinancialInformation(execution, dataSources)
  );
};

export const hasDriverInformation = (execution, dataSources) => {
  const sources = ['denatranDriverViolations', 'denatranVehicleViolations'];

  const foundSource = sources.find((s) => findSourceSection(execution, s));

  return (
    foundSource ||
    dataSources?.includes('detran') ||
    dataSources?.includes('antt') ||
    execution?.driver?.detran ||
    execution?.driver?.antt ||
    execution?.sections?.detran ||
    execution?.sections?.antt
  );
};

export const hasCriminalInformation = (execution, dataSources) => {
  const sources = [
    'pfAntecedentes',
    'pfCriminalBackground',
    'pfArrestWarrant',
    'pjAntecedentes',
    'pjCriminalBackground',
    'pjArrestWarrant',
    'pfNadaConsta',
    'pfBackgroundCheckTrf',
    'pfBackgroundCheckFederalPolice',
  ];

  const foundSource = sources.find((s) => findSourceSection(execution, s));

  return (
    foundSource ||
    dataSources?.includes('pf_antecedentes') ||
    dataSources?.includes('pf_criminal_background') ||
    dataSources?.includes('pf_nada_consta') ||
    dataSources?.includes('pf_background_check_federal_police') ||
    execution?.criminal?.stateCriminalBackground ||
    execution?.criminal?.federalCriminalBackground ||
    execution?.sections?.pfAntecedentes ||
    execution?.sections?.pfCriminalBackground ||
    execution?.sections?.pfArrestWarrant ||
    execution?.sections?.pjAntecedentes ||
    execution?.sections?.pjCriminalBackground ||
    execution?.sections?.pjArrestWarrant ||
    execution?.sections?.pfNadaConsta ||
    execution?.sections?.pfBackgroundCheckTrf ||
    execution?.sections?.pfBackgroundCheckFederalPolice
  );
};

export const hasPJLaborInformation = (execution, _) => {
  const sources = ['pjFgts', 'pjSintegra', 'pjCndt', 'pjLaborDebts'];

  const foundSource = sources.find((s) => findSourceSection(execution, s));

  return (
    foundSource ||
    execution?.sections?.pjFgts ||
    execution?.certificates?.fgts ||
    execution?.sections?.pjSintegra ||
    execution?.certificates?.sintegra ||
    execution?.sections?.pjCndt ||
    execution?.certificates?.cndt ||
    execution?.sections?.pjLaborDebts
  );
};
export const hasPFLaborInformation = (execution, _) => {
  const sources = [
    'pfOccupationexecution',
    'pfProfessionexecution',
    'pfProfessionData',
    'pfOccupationData',
    'pfClassOrganization',
    'pfRais',
    'pfLaborDebts',
    'pfBusinessRelationships',
  ];

  const foundSource = sources.find((s) => findSourceSection(execution, s));

  return (
    foundSource ||
    execution?.sections?.pfOccupationexecution ||
    execution?.sections?.pfProfessionexecution ||
    execution?.sections?.pfProfessionData ||
    execution?.sections?.pfOccupationData ||
    execution?.sections?.pfClassOrganization ||
    execution?.sections?.pfRais ||
    execution?.sections?.pfBusinessRelationships ||
    execution?.sections?.pfLaborDebts
  );
};

export const hasLaborInformation = (execution, dataSources) => {
  return (
    hasPJLaborInformation(execution, dataSources) ||
    hasPFLaborInformation(execution, dataSources)
  );
};

export const hasLegalInformation = (execution, dataSources) => {
  const sources = [
    'pfProcesses',
    'pjProcesses',
    'pfProcessesAlt',
    'pjProcessesAlt',
    'pfProcessOnCade',
    'pfProcessesWithKeywords',
    'pjProcessesWithKeywords',
  ];

  const foundSource = sources.find((s) => findSourceSection(execution, s));

  return (
    foundSource ||
    dataSources?.includes('pf_processes') ||
    dataSources?.includes('pf_processes_alt') ||
    dataSources?.includes('pf_process_on_cade') ||
    execution?.justice?.processes ||
    execution?.sections?.pfProcesses ||
    execution?.sections?.pfProcessesAlt ||
    execution?.sections?.pjProcesses ||
    execution?.sections?.pjProcessesAlt ||
    execution?.sections?.pfProcessOnCade ||
    execution?.sections?.pfProcessesWithKeywords ||
    execution?.sections?.pjProcessesWithKeywords
  );
};

export const hasRestrictiveListsInformation = (execution, dataSources) => {
  const sources = [
    'pfKycCompliance',
    'pjKycCompliance',
    'pjKycComplianceOwners',
    'pfKycComplianceOwners',
    'kycComplianceOwners',
    'suspiciousPlaces',
    'pfDenyListPrivate',
    'pjDenyListPrivate',
    'peopleSportRelated',
    'pfOfacSanctionsList',
    'pjOfacSanctionsList',
  ];

  const foundSource = sources.find((s) => findSourceSection(execution, s));

  return (
    foundSource ||
    dataSources?.includes('pf_kyc_compliance') ||
    dataSources?.includes('pj_kyc_compliance_owners') ||
    dataSources?.includes('pj_kyc_compliance') ||
    dataSources?.includes('suspicious_places') ||
    dataSources?.includes('suspicious_places') ||
    execution?.others?.kycCompliance ||
    execution?.others?.kycComplianceOwners ||
    execution?.sections?.pfKycCompliance ||
    execution?.sections?.pjKycCompliance ||
    execution?.sections?.pjKycComplianceOwners ||
    execution?.sections?.pfKycComplianceOwners ||
    execution?.sections?.kycComplianceOwners ||
    execution?.sections?.suspiciousPlaces ||
    execution?.sections?.pfDenyListPrivate ||
    execution?.sections?.pjDenyListPrivate ||
    execution?.sections?.pfOfacSanctionsList ||
    execution?.sections?.pjOfacSanctionsList
  );
};

export const hasBasicInformation = (entity, dataSources) => {
  const sources = [
    'pjData',
    'pfBasicData',
    'cpf',
    'pfData',
    'pfCpfData',
    'pfPersonalRelationships',
    'pfAddresses',
    'pfAddressesAlt',
    'pfLocalizaAssertiva',
    'pfBiometriaUnico',
    'pfAddressAuthentication',
    'phoneTrustScore',
    'simSwapAlert',
    'phoneAnalysis',
    'emailAnalysis',
    'globalIdentityVerifications',
    'globalIdentityVerifications2x2',
    'kycSanctionsEmergingCountries',
    'kycPepsEmergingCountries',
    'kycSanctionsLatamCountries',
    'kycPepsLatamCountries',
    'kycAdverseMediaEmergingCountries',
    'kycAdverseMediaLatamCountries',
    'kycWarningsEmergingCountries',
    'kycWarningsLatamCountries',
    'sanctions',
  ];

  const foundSource = sources.find((s) => findSourceSection(entity, s));

  return (
    foundSource ||
    dataSources?.includes('pjData') ||
    dataSources?.includes('phoneTrustScore') ||
    hasAddressInformation(entity, dataSources)
  );
};

export const hasRelationshipInformation = (execution, dataSources) => {
  return (
    dataSources?.includes('pf_related_people_phones') ||
    dataSources?.includes('pf_business_relationships') ||
    dataSources?.includes('pf_personal_relationships') ||
    dataSources?.includes('pf_personal_relationships_trust_rl') ||
    execution?.relationships?.peoplePhones ||
    execution?.relationships?.business ||
    execution?.relationships?.personal ||
    execution?.relationships?.peopleOccurrencesIRL ||
    execution?.sections?.pfRelatedPeoplePhones ||
    execution?.sections?.pfBusinessRelationships ||
    execution?.sections?.pfPersonalRelationships ||
    execution?.sections?.pjRelatedPeoplePhones ||
    execution?.sections?.pjBusinessRelationships ||
    execution?.sections?.pjPersonalRelationships
  );
};

export const hasPFMediaExposureInformation = (execution, dataSources) => {
  const sources = ['pfMediaProfileAndExposure', 'pfDomains'];

  const foundSource = sources.find((s) => findSourceSection(execution, s));

  return (
    foundSource ||
    dataSources?.includes('pf_media_profile_and_exposure') ||
    dataSources?.includes('pf_domains') ||
    execution?.sections?.pfMediaProfileAndExposure ||
    execution?.sections?.pfDomains ||
    execution?.others?.mediaExposure
  );
};

export const hasPJMediaExposureInformation = (execution, dataSources) => {
  const sources = ['pjMediaProfileAndExposure'];

  const foundSource = sources.find((s) => findSourceSection(execution, s));

  return (
    foundSource ||
    dataSources?.includes('pj_media_profile_and_exposure') ||
    execution?.sections?.pjMediaProfileAndExposure ||
    execution?.others?.mediaExposure
  );
};

export const hasMediaExposureInformation = (execution, dataSources) => {
  return (
    hasPFMediaExposureInformation(execution, dataSources) ||
    hasPJMediaExposureInformation(execution, dataSources)
  );
};

export const hasCreditOperationsScr = (execution, dataSources) => {
  return (
    dataSources?.includes('pj_credit_operations_scr') ||
    dataSources?.includes('pf_credit_operations_scr') ||
    execution?.sections?.pfCreditOperationsScr ||
    execution?.sections?.pjCreditOperationsScr
  );
};

export const hasZipCodeDetails = (execution, dataSources) => {
  return dataSources?.includes('zip_code_details') || execution?.sections?.zipCodeDetails;
};

export const hasEmailAgePrivate = (execution) => {
  return findSourceSection(execution, 'emailAgePrivate');
};

export const hasTrustInformation = (execution, dataSources) => {
  return (
    hasFinancialInformation(execution, dataSources) ||
    hasDriverInformation(execution, dataSources) ||
    hasCriminalInformation(execution, dataSources) ||
    hasLaborInformation(execution, dataSources) ||
    hasLegalInformation(execution, dataSources) ||
    hasRestrictiveListsInformation(execution, dataSources) ||
    hasRelationshipInformation(execution, dataSources) ||
    hasMediaExposureInformation(execution, dataSources) ||
    hasBasicInformation(execution, dataSources) ||
    hasCreditOperationsScr(execution, dataSources) ||
    hasZipCodeDetails(execution, dataSources) ||
    hasEmailAgePrivate(execution) ||
    hasContactsInformation(execution, dataSources)
  );
};

// extra

export const hasPersonalAndBusinessRelationshipInformation = (execution, dataSources) => {
  return (
    dataSources?.includes('pf_business_relationships') ||
    dataSources?.includes('pf_personal_relationships') ||
    execution?.relationships?.business ||
    execution?.relationships?.personal ||
    execution?.sections?.pfBusinessRelationships ||
    execution?.sections?.pfPersonalRelationships ||
    execution?.sections?.pjBusinessRelationships ||
    execution?.sections?.pjPersonalRelationships
  );
};

export const hasDetranInformation = (execution, dataSources) => {
  return (
    dataSources?.includes('detran') ||
    execution?.driver?.detran ||
    execution?.sections?.detran
  );
};

export const hasElectoralSituationInformation = (execution, dataSources) => {
  return (
    dataSources?.includes('pf_electoral_situation') ||
    execution?.others?.electoralSituation ||
    execution?.sections?.pfElectoralSituation ||
    execution?.sections?.pjElectoralSituation
  );
};

export const hasPjSintegraData = (execution, dataSources) => {
  return dataSources?.includes('pjSintegraData') || execution?.sections?.pjSintegraData;
};
